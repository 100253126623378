





















import { Vue, Component, Prop } from 'vue-property-decorator'

export type MenuItem = { text: string; action: Function }
@Component({})
export default class KebabMenu extends Vue {
  @Prop({ required: true }) menuItems!: MenuItem[]
}
