








import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class BackButton extends Vue {
  @Prop({ default: 'Back', type: String }) text!: string
  @Prop({ default: '', type: String }) to!: string

  onClick() {
    this.$emit('click')
  }
}
