








































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import { LotDTO, LotStatus } from '@/api-client'
import { formatAUD, ordinal } from '@/modules/stringUtils'
import { DataTableHeader } from 'vuetify/types/index'
import { PropertyType } from '@/api-client'
import { sortByMaybeNumberField } from '@/utils/sort'

import KebabMenu, { MenuItem } from '@/components/KebabMenu.vue'

@Component({
  components: {
    ValidationProvider,
    KebabMenu,
  },
})
export default class LotPreferenceForm extends Vue {
  @Prop({ required: true }) lots!: LotDTO[]
  @Prop({ required: true }) propertyType!: PropertyType
  @Prop({ type: Boolean }) disabled!: boolean
  @Prop({ type: Boolean }) block!: boolean
  @Prop({ type: Boolean }) preferredOnly!: boolean
  @Prop({ type: Boolean }) hidePreference!: boolean
  @Prop({ type: Boolean }) canToggleAllocation!: boolean
  @Prop({ type: String }) releaseId: string | undefined
  @Prop({ default: () => [] }) value!: { [id: string]: number }
  @Prop({ default: () => ({}) }) lotStatuses:
    | Record<string, LotStatus>
    | undefined
  @Prop({ type: Boolean }) canPurchase!: boolean

  PropertyType = PropertyType

  expandedPanels: number[] = []
  prefColumnWidth = '106'
  ordinal = ordinal
  isSortedByName = true
  isMobileExpanded = false
  headerClasses = 'primary--text text-h5 grey-cultured-light table-header'
  allocationToggleLoading = false
  allocationToggle: Record<string, boolean> = {}

  @Watch('expandedPanels')
  setExpandedFlag() {
    if (this.expandedPanels.length === 0) this.isMobileExpanded = false
    else if (this.expandedPanels === this.lots.map((_, i) => i))
      this.isMobileExpanded = true
  }

  @Watch('isMobileExpanded')
  expandMobile(flag: boolean) {
    this.expandedPanels = flag ? this.lots.map((_, i) => i) : []
  }

  menuItems(lot: LotDTO): MenuItem[] {
    const actions: MenuItem[] = []
    if (this.isNotAvailable(lot.id!)) {
      actions.push({
        text: 'Make Available',
        action: async () => await this.setRemovedFromSale(lot.id!, false),
      })
    }
    if (this.isAvailable(lot.id!)) {
      actions.push({
        text: 'Make Unavailable',
        action: async () => await this.setRemovedFromSale(lot.id!, true),
      })
    }

    return actions
  }

  buildHeaders() {
    if (this.propertyType === PropertyType.Apartment) {
      return [
        {
          text: 'Apartment Number',
          class: this.headerClasses,
          align: 'start',
          sortable: this.lots.length > 1,
          value: 'name',
        },
        {
          text: 'Bedrooms',
          class: this.headerClasses,
          align: 'start',
          sortable: this.lots.length > 1,
          value: 'bedrooms',
        },
        {
          text: 'Bathrooms',
          class: this.headerClasses,
          align: 'start',
          sortable: this.lots.length > 1,
          value: 'bathrooms',
        },
        {
          text: 'Car Spaces',
          class: this.headerClasses,
          align: 'start',
          sortable: this.lots.length > 1,
          value: 'carSpaces',
        },
        {
          text: 'Area (m²)',
          class: this.headerClasses,
          align: 'start',
          sortable: this.lots.length > 1,
          value: 'size',
        },
        {
          text: 'Features',
          class: this.headerClasses,
          align: 'start',
          sortable: false,
          value: 'description',
        },
        {
          text: 'Price',
          class: this.headerClasses,
          align: 'start',
          sortable: this.lots.length > 1,
          value: 'price',
        },
      ]
    }
    if (this.propertyType === PropertyType.Lot) {
      return [
        {
          text: 'Lot number',
          class: this.headerClasses,
          align: 'start',
          sortable: this.lots.length > 1,
          value: 'name',
        },
        {
          text: 'Depth (m)',
          class: this.headerClasses,
          align: 'start',
          sortable: this.lots.length > 1,
          value: 'length',
        },
        {
          text: 'Width (m)',
          class: this.headerClasses,
          align: 'start',
          sortable: this.lots.length > 1,
          value: 'width',
        },
        {
          text: 'Area (m²)',
          class: this.headerClasses,
          align: 'start',
          sortable: this.lots.length > 1,
          value: 'size',
        },
        {
          text: 'Lot details',
          class: this.headerClasses,
          align: 'start',
          sortable: false,
          value: 'description',
        },
        {
          text: 'Price',
          class: this.headerClasses,
          align: 'start',
          sortable: this.lots.length > 1,
          value: 'price',
        },
      ]
    }
    if (this.propertyType === PropertyType.Townhouse) {
      return [
        {
          text: 'Lot Number',
          class: this.headerClasses,
          align: 'start',
          sortable: this.lots.length > 1,
          value: 'name',
        },
        {
          text: 'Bedrooms',
          class: this.headerClasses,
          align: 'start',
          sortable: this.lots.length > 1,
          value: 'bedrooms',
        },
        {
          text: 'Bathrooms',
          class: this.headerClasses,
          align: 'start',
          sortable: this.lots.length > 1,
          value: 'bathrooms',
        },
        {
          text: 'Garage Spaces',
          class: this.headerClasses,
          align: 'start',
          sortable: this.lots.length > 1,
          value: 'carSpaces',
        },
        {
          text: 'Land Area (m²)',
          class: this.headerClasses,
          align: 'start',
          sortable: this.lots.length > 1,
          value: 'landArea',
        },
        {
          text: 'Building Area (m²)',
          class: this.headerClasses,
          align: 'start',
          sortable: this.lots.length > 1,
          value: 'buildingArea',
        },
        {
          text: 'Features',
          class: this.headerClasses,
          align: 'start',
          sortable: false,
          value: 'description',
        },
        {
          text: 'Price',
          class: this.headerClasses,
          align: 'start',
          sortable: this.lots.length > 1,
          value: 'price',
        },
      ]
    }
  }

  get headers() {
    return (this.buildHeaders() as DataTableHeader[])
      .concat(
        this.hidePreference
          ? []
          : [
              {
                text: 'Preference #',
                width: `${this.prefColumnWidth}px`,
                class: this.headerClasses,
                align: 'start',
                sortable: false,
                value: 'preference',
              },
            ],
      )
      .concat(
        this.canToggleAllocation
          ? [
              // status column
              {
                text: '',
                class: this.headerClasses,
                align: 'center',
                sortable: false,
                value: 'status',
              },
              // management column
              {
                text: '',
                class: this.headerClasses,
                align: 'center',
                sortable: false,
                value: 'toggleAllocation',
              },
            ]
          : [],
      )
      .concat(
        this.canPurchase
          ? [
              {
                text: 'Purchase',
                class: this.headerClasses,
                align: 'center',
                sortable: false,
                value: 'purchase',
              },
            ]
          : [],
      )
  }

  get transformedLots() {
    const keys = Object.keys(this.value)
    return [...this.lots]
      .filter(lot =>
        this.preferredOnly
          ? keys.includes(lot.id!) && (this.value[lot.id!] as unknown) !== ''
          : true,
      )
      .sort((a, b) => {
        if (this.preferredOnly) {
          // sort by preference
          return this.value[a.id!] - this.value[b.id!]
        } else {
          // sort by name or price
          return this.isSortedByName
            ? sortByMaybeNumberField(a.name, b.name)
            : a.price! - b.price!
        }
      })
      .map(l => ({
        id: l.id,
        name: l.name,
        description: l.description,
        length: l.length,
        width: l.width,
        size: l.size,
        price: formatAUD(l.price!),
        bedrooms: l.bedrooms,
        bathrooms: l.bathrooms,
        buildingArea: l.buildingArea,
        carSpaces: l.carSpaces,
        landArea: l.landArea,
      }))
  }

  async toggleAllocation(id: string) {
    this.allocationToggleLoading = true
    const originalValue = this.allocationToggle[id]
    const updatedValue = !originalValue
    return await this.setRemovedFromSale(id, updatedValue)
  }

  async setRemovedFromSale(id: string, removedFromSale: boolean) {
    this.allocationToggleLoading = true
    try {
      const index = this.lots.findIndex(l => l.id === id)
      await this.$api.release.v1ReleasesReleaseIdLotsLotIdPost(
        this.releaseId!,
        id,
        {
          isRemovedFromSale: removedFromSale,
        },
      )
      this.lots[index].isRemovedFromSale = removedFromSale
      this.allocationToggleLoading = false
      this.allocationToggle[id] = removedFromSale
      this.$emit('on-toggle-allocation', this.lots[index])
    } catch (e) {
      this.allocationToggleLoading = false
      this.allocationToggle[id] = removedFromSale
    }
  }

  emit() {
    this.$emit('input', this.value)
  }

  mounted() {
    if (this.lots.length === 1) this.expandedPanels = [0]
    if (this.canToggleAllocation && !this.releaseId)
      throw new Error('must specify releaseId to toggle lot allocation')
    for (const lot of this.lots) {
      this.$set(this.allocationToggle, lot.id!, lot.isRemovedFromSale ?? false)
    }
  }

  lotStatus(lotId: string) {
    if (!this.lotStatuses) return undefined
    return this.lotStatuses[lotId]
  }

  isAvailable(lotId: string) {
    return this.lotStatus(lotId) == LotStatus.Available
  }

  isSecured(lotId: string) {
    const status = this.lotStatus(lotId)
    return status == LotStatus.Secured
  }

  isNotAvailable(lotId: string) {
    const status = this.lotStatus(lotId)
    return status == LotStatus.Secured || status == LotStatus.Unavailable
  }

  isOnHold(lotId: string) {
    return this.lotStatus(lotId) == LotStatus.OnHold
  }

  statusColor(lotId: string) {
    if (!this.lotStatuses) return undefined

    switch (this.lotStatuses[lotId]) {
      case LotStatus.Available:
        return 'success'
      case LotStatus.OnHold:
        return 'orange'
      case LotStatus.Secured:
      case LotStatus.Unavailable:
        return 'error'
      default:
        return undefined
    }
  }

  startPurchase(lot: LotDTO) {
    this.$emit('purchase', lot)
  }
}
